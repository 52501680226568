import { AdminStoreProperties } from './adminStoreProperties';

export const adminStoresHeaders = [{
    // TODO: add translate key
    text: 'Store & Account Information',
    property: [{
        text: 'common__company__name',
        property: AdminStoreProperties.companyName,
        sortable: true
    }, {
        text: 'common__brand',
        property: AdminStoreProperties.brandName,
        sortable: true
    }, {
        text: 'common__store__number',
        property: AdminStoreProperties.storeNumber,
        sortable: true
    }, {
        text: 'common__store__name',
        property: AdminStoreProperties.storeName,
        sortable: true
    }, {
        text: 'common__store__address',
        property: AdminStoreProperties.storeAddress,
        sortable: true
    }, {
        text: 'common__account__email',
        property: AdminStoreProperties.userEmail,
        sortable: true,
        flex: 2
    }, {
        text: 'common__ax-account--number',
        property: AdminStoreProperties.axAccountNumber
    }, {
        text: 'help__faq__subtitle--subscription',
        property: AdminStoreProperties.userSubscription,
        sortable: true,
        flex: 1.25
    }, {
        // TODO: add translate key
        text: 'STORE ACTIONS',
        property: 'Actions',
        className: 'hme-stores-status--admin__grid__cell--store-actions',
        headerClassName: 'hme-stores-status--admin__grid__cell--store-actions'
    }]
}, {
    // TODO: add translate key
    text: 'Device Information',
    property: [{
        text: 'common__device__serial-number--text',
        property: AdminStoreProperties.deviceSerialNumber,
        flex: 1.25,
        sortable: true
    }, {
        text: 'common__device__product-id',
        flex: 1,
        property: AdminStoreProperties.deviceProductID,
        sortable: true
    }, {
        text: 'common__device__version',
        property: AdminStoreProperties.deviceVersion,
        sortable: true,
        flex: 0.75
    }, {
        text: 'common__status',
        property: AdminStoreProperties.deviceStatus,
        sortable: true,
        className: 'hme-stores-status__list__status',
        headerClassName: 'hme-stores-status--admin__grid__header__status hme-grid-filter-align-right',
        flex: 1.5
    }]
}];
