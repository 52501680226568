import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAccess } from 'hooks/useAccess';
import { getAdminStoreDataFromApi, removeDeviceFromStore, removeStore, updateStore } from 'services/Stores';
import { addPreconfiguredDeviceToStore } from 'services/Stores';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import getAvailableDevices from 'helpers/Store/getAvailableDevices';
import filterSubscriptionAllowedDevices from 'helpers/Store/filterSubscriptionAllowedDevices';
import { getSubscriptionsValue } from '../../Common/helpers/getSubscriptionsValue';
import { isValuesEqual } from '../../Common/helpers/isValuesEqual';
import { useLoadStoreSystemTiers } from '../../Common/hooks/useLoadStoreSystemTiers';

import {
    ERROR_MESSAGE_T_KEY,
    SUCCESS_MESSAGE_T_KEY,
    SUCCESS_MESSAGE_ADD_DEVICE_T_KEY,
    SUCCESS_MESSAGE_REMOVE_STORE_T_KEY,
    REMOVE_DEVICES_CONFIRM_MESSAGE_T_KEY,
    REMOVE_STORE_CONFIRM_MESSAGE_T_KEY, SUCCESS_MESSAGE_REMOVE_DEVICES_T_KEY
} from '../../constants/messagesTKeys';
import { getDeviceRows } from '../../Public/helpers/getDeviceRows';
import { validateAXAccountNumber } from 'helpers/Store/validateAXAccountNumber';
import { headers } from '../../Public/DevicesInformation/headers';
import { CommonConstants } from 'Constants';
import { getTiersByDeviceType } from '../../Common/helpers/getTiersByDeviceType';
import { getDeviceSubscription } from '../../Common/helpers/getDeviceSubscription';
const { deviceType: deviceTypesMap, adminPermissions, defaultDropdownOption } = CommonConstants;

const DEFAULT_STORE_DETAILS = {
    Store_Name: '',
    Brand_Name: '',
    Store_Number: '',
    GroupName: '',
    Store_PhoneNumber: '',
    Store_FaxNumber: '',
    Store_AddressLine1: '',
    Store_AddressLine2: '',
    Store_AddressLine3: '',
    Store_AddressLine4: '',
    Store_AX_Customer_Account: '',
    Store_AX_Invoice_Account: '',
    Store_Vaio_Subscription: '',
    Timezone: '',
    Group_Name: ''
};

export const DEFAULT_SUBSCRIPTION = { name: 'common__none', id: -1 };

const getStoreDetailsFromApi = async (
        suid,
        setStoreDetails,
        setDeviceDetails,
        setStoreDetailsLoading,
        setAXCustomerNumber,
        setVaioSubscription,
        setVaioSubscriptionList,
        setInitialVaioProvider,
        setNexeoSystemTierID,
        setJTechTierID,
        setClassificationId,
        setNitroSubscriptionId
) => {
    try {
        setStoreDetailsLoading(true);
        const results = await getAdminStoreDataFromApi(suid);
        const {
            storeDetails: storeData,
            Device_Details: devicesDetails,
            storeVaioProvider,
            vaioProviders,
            systemTiers,
        } = results;

        const deviceData = Array.isArray(devicesDetails) ? devicesDetails : [];

        // Assumption: there is only one tier per device type
        const [nexeoSystemTier] = getTiersByDeviceType(deviceTypesMap.nexeo.id, systemTiers);
        const [jTechTier] = getTiersByDeviceType(deviceTypesMap.linkWear.id, systemTiers);
        setNexeoSystemTierID(nexeoSystemTier?.id || '');
        setJTechTierID(jTechTier?.id || DEFAULT_SUBSCRIPTION.id);

        setAXCustomerNumber(storeData.Store_AX_Customer_Account || '');
        setClassificationId(storeData.StoreClassification_ID || 1);
        setNitroSubscriptionId(storeData.SubscriptionID);
        setStoreDetails({
            ...storeData,
            nexeoSystemTier,
            jTechTier
        });
        setDeviceDetails(deviceData);
        setVaioSubscriptionList(defaultDropdownOption.concat(vaioProviders));

        if (storeVaioProvider) {
            setInitialVaioProvider(storeVaioProvider);
            setVaioSubscription(storeVaioProvider.id);
        }
    } catch (error) {
        addErrorNotification(ERROR_MESSAGE_T_KEY);
    } finally {
        setStoreDetailsLoading(false);
    }
};

const STORES_PAGE = '/settings/stores';
const ADMIN_STORES_PAGE = '/admin/settings/stores';

const getRedirectUrl = (uuid) => uuid ? `${STORES_PAGE}?uuid=${uuid}` : ADMIN_STORES_PAGE;
const getDeviceTypeByName = (deviceTypeName) =>
    Object.values(deviceTypesMap).find((dt) => dt.name === deviceTypeName) || {};

const getActiveDevices = (deviceDetails) => {
    let isStoreHaveActiveNexeoDevice = false;
    let isStoreHaveActiveZoomDevice = false;
    deviceDetails.forEach(({
        Device_Name: deviceName,
        Device_IsActive: isDeviceActive,
        Device_IsPreconfigured: isDevicePreconfigured
    }) => {
        if (deviceName.includes('NEXEO') && (isDeviceActive || isDevicePreconfigured)) {
            isStoreHaveActiveNexeoDevice = true;
        } else if (deviceName.includes('ZOOM') && (isDeviceActive || isDevicePreconfigured)) {
            isStoreHaveActiveZoomDevice = true;
        }
    });
    return isStoreHaveActiveNexeoDevice && isStoreHaveActiveZoomDevice;
};

const addPreconfiguredDevice = async ({
    payload,
    setLoading,
    suid,
    setStoreDetails,
    setDeviceDetails,
    setStoreDetailsLoading,
    setAXCustomerNumber,
    setVaioSubscription,
    setVaioSubscriptionList,
    setInitialVaioProvider,
    setNexeoSystemTierID,
    setJTechTierID,
    setClassificationId,
    setNitroSubscriptionId
}) => {
    setLoading(true);

    try {
        await addPreconfiguredDeviceToStore(payload);
        await getStoreDetailsFromApi(
                suid,
                setStoreDetails,
                setDeviceDetails,
                setStoreDetailsLoading,
                setAXCustomerNumber,
                setVaioSubscription,
                setVaioSubscriptionList,
                setInitialVaioProvider,
                setNexeoSystemTierID,
                setJTechTierID,
                setClassificationId,
                setNitroSubscriptionId
        );
        addSuccessNotification(SUCCESS_MESSAGE_ADD_DEVICE_T_KEY);
    } catch (err) {
        addErrorNotification(ERROR_MESSAGE_T_KEY);
    }

    setLoading(false);
};

const ACTIONS_WITH_REMOVE = [{
    text: 'common__actions',
    className: 'hme-grid-centered-cell-actions',
    headerClassName: 'hme-grid-centered-cell',
    property: 'Actions',
    flex: 2
}];

export const useViewEdit = () => {
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();

    const [snapshot, setSnapshot] = useState();
    const [deviceType, setDeviceType] = useState('');
    const [isStoreDetailsLoading, setStoreDetailsLoading] = useState(false);
    const [storeDetails, setStoreDetails] = useState(DEFAULT_STORE_DETAILS);
    const [deviceDetails, setDeviceDetails] = useState([]);
    const [AXCustomerNumber, setAXCustomerNumber] = useState('');
    const [vaioSubscription, setVaioSubscription] = useState(-1);
    const [nexeoSystemTierID, setNexeoSystemTierID] = useState('');
    const [jTechTierID, setJTechTierID] = useState(DEFAULT_SUBSCRIPTION.id);
    const [vaioSubscriptionList, setVaioSubscriptionList] = useState([]);
    const [initialVaioProvider, setInitialVaioProvider] = useState(DEFAULT_SUBSCRIPTION);
    const [devicesRows, setDevicesRows] = useState([]);
    const [validationErrors, setValidationError] = useState({});
    const [isModalOpen, setModalOpen] = useState(false);
    const [isConfirmPopupOpen, setConfirmPopupOpen] = useState(false);
    // eslint-disable-next-line no-empty-function
    const [confirmPopupContent, setConfirmPopupContent] = useState({ message: '', onConfirm: () => { } });
    const [classificationId, setClassificationId] = useState(1);
    const [nitroSubscriptionId, setNitroSubscriptionId] = useState(null);

    const suid = useMemo(() => queryParams.get('suid'), [queryParams]);
    const uuid = useMemo(() => queryParams.get('uuid'), [queryParams]);

    const hasManageTierPermission = useAccess(adminPermissions.ManageTier);
    const isStoreSystemTiersDisabled = !hasManageTierPermission;

    const { isJTechCustomerOnlySubscription, isNexeoCustomerOnlySubscription, isNitroSubscription } = useMemo(() =>
        getSubscriptionsValue(String(storeDetails['Account_Subscription_ID'])), [storeDetails['Account_Subscription_ID']]);

    const { isNVDEnabled } = getDeviceSubscription(deviceDetails);

    const hasManageNitroSubscriptionPermission = useAccess(adminPermissions.ManageNitroSubscription);

    const devicesHeaders = useMemo(() => {
        const isStoreWithoutOnlineDevices = deviceDetails.some((device) => !device.Device_IsActive);
        if (isStoreWithoutOnlineDevices) {
            const arrayWithoutActions = headers.filter(({ property }) => property !== 'Actions');
            return [...arrayWithoutActions, ...ACTIONS_WITH_REMOVE];
        }
        return headers;
    }, [deviceDetails]);

    const availableDevices = useMemo(
            () =>
                filterSubscriptionAllowedDevices(
                        getAvailableDevices(deviceDetails),
                        storeDetails['Account_Subscription_ID']
                ),
            [deviceDetails, storeDetails, filterSubscriptionAllowedDevices, getAvailableDevices]
    );

    const { storeSystemTiers, isStoreSystemTiersLoading } = useLoadStoreSystemTiers();

    useEffect(() => {
        getStoreDetailsFromApi(
                suid,
                setStoreDetails,
                setDeviceDetails,
                setStoreDetailsLoading,
                setAXCustomerNumber,
                setVaioSubscription,
                setVaioSubscriptionList,
                setInitialVaioProvider,
                setNexeoSystemTierID,
                setJTechTierID,
                setClassificationId,
                setNitroSubscriptionId
        );
    }, [suid]);

    const onConfirmPopupClose = useCallback(() =>
        setConfirmPopupOpen(false),
    [setConfirmPopupOpen]);

    const onConfirmRemoveDevices = useCallback(async (DeviceUID) => {
        onConfirmPopupClose();
        setStoreDetailsLoading(true);
        try {
            const params = {
                DeviceUID,
                StoreID: storeDetails.Store_ID
            };
            await removeDeviceFromStore(params);
            await getStoreDetailsFromApi(
                    suid,
                    setStoreDetails,
                    setDeviceDetails,
                    setStoreDetailsLoading,
                    setAXCustomerNumber,
                    setVaioSubscription,
                    setVaioSubscriptionList,
                    setInitialVaioProvider,
                    setNexeoSystemTierID,
                    setJTechTierID,
                    setClassificationId,
                    setNitroSubscriptionId
            );
            addSuccessNotification(SUCCESS_MESSAGE_REMOVE_DEVICES_T_KEY);
        } catch (error) {
            addErrorNotification(ERROR_MESSAGE_T_KEY);
        } finally {
            setStoreDetailsLoading(false);
        }
    }, [
        storeDetails,
        addSuccessNotification,
        addErrorNotification,
        onConfirmPopupClose,
        suid
    ]);

    const onRemoveDevicesConfirmModalOpen = useCallback((deviceUID = '') => {
        setConfirmPopupContent({
            message: REMOVE_DEVICES_CONFIRM_MESSAGE_T_KEY,
            onConfirm: () => onConfirmRemoveDevices(deviceUID)
        });
        setConfirmPopupOpen(true);
    }, [setConfirmPopupContent, onConfirmRemoveDevices, setConfirmPopupOpen]);

    const onRemoveDevice = useCallback((deviceUID) =>
        onRemoveDevicesConfirmModalOpen(deviceUID),
    [onRemoveDevicesConfirmModalOpen]);

    useEffect(() => {
        setDevicesRows(getDeviceRows(deviceDetails, onRemoveDevice));
    }, [setDevicesRows, deviceDetails, onRemoveDevice]);

    const onCancel = useCallback(() => {
        const url = getRedirectUrl(uuid);
        navigate(url);
    }, [navigate, uuid]);

    const onVaioSubChange = (e) => {
        setVaioSubscription(e);
    };

    const onStoreSystemTierChange = (systemTierID) => {
        setNexeoSystemTierID(systemTierID);
    };

    const onJTechTierChange = (jTechTier) => {
        setJTechTierID(jTechTier);
    };

    const onSave = useCallback(async () => {
        setValidationError({});

        if (!validateAXAccountNumber(AXCustomerNumber)) {
            setValidationError({ invalidAXCustomerNumber: 'stores__error--invalid-ax-account-number' });
            return;
        }

        try {
            setStoreDetailsLoading(true);
            const newVaioSubscription = initialVaioProvider.id === -1;
            const nexeo = CommonConstants.deviceType.nexeo.name;
            const nexeoDevice = deviceDetails.find((device) => device.Device_Name.includes(nexeo));
            const { Device_UID: nexeoUID } = nexeoDevice || {};

            const { Store_UID: storeUid, Store_ID: storeID } = storeDetails;
            const systemTierIDs = nexeoSystemTierID && jTechTierID !== DEFAULT_SUBSCRIPTION.id ? [nexeoSystemTierID, jTechTierID]
            : nexeoSystemTierID ? [nexeoSystemTierID]
            : jTechTierID !== DEFAULT_SUBSCRIPTION.id ? [jTechTierID]
            : [];
            const storeNewData = {
                isAdmin: 1,
                suid: storeUid,
                storeID,
                AXCustomerAccount: AXCustomerNumber,
                newVaioSubscription,
                vaioProviderID: vaioSubscription,
                nexeoUID,
                systemTierIDs,
                storeClassificationId: classificationId,
                subscriptionID: ((nitroSubscriptionId !== storeDetails.Account_Subscription_ID) || storeDetails.SubscriptionID) && nitroSubscriptionId
            };
            await updateStore(storeNewData);

            const url = getRedirectUrl(uuid);
            navigate(url);
            setTimeout(() => {
                addSuccessNotification(SUCCESS_MESSAGE_T_KEY);
            }, 1000);
            return;
        } catch (error) {
            addErrorNotification(ERROR_MESSAGE_T_KEY);
            return;
        } finally {
            setStoreDetailsLoading(false);
        }
    }, [
        addSuccessNotification,
        addErrorNotification,
        storeDetails,
        suid,
        uuid,
        nexeoSystemTierID,
        jTechTierID,
        AXCustomerNumber,
        navigate,
        vaioSubscription,
        classificationId,
        nitroSubscriptionId
    ]);

    const isSaveBtnDisabled = useMemo(() => {
        const checkVaioSubscription = vaioSubscription || -1;

        return isValuesEqual(AXCustomerNumber, storeDetails.Store_AX_Customer_Account)
            && checkVaioSubscription == initialVaioProvider.id
            && isValuesEqual(nexeoSystemTierID, storeDetails?.nexeoSystemTier?.id)
            && isValuesEqual(jTechTierID, storeDetails?.jTechTier?.id || DEFAULT_SUBSCRIPTION.id)
            && classificationId === storeDetails.StoreClassification_ID
            && ((!storeDetails.SubscriptionID) ? (nitroSubscriptionId === storeDetails.Account_Subscription_ID) : (nitroSubscriptionId === storeDetails.SubscriptionID));
    }, [AXCustomerNumber, vaioSubscription, storeDetails, nexeoSystemTierID, jTechTierID, classificationId, nitroSubscriptionId]);

    const onAddDeviceClick = useCallback(() => setModalOpen(true), [setModalOpen, isModalOpen]);

    const onModalClose = useCallback((needToLoadDevices = false) => {
        setModalOpen(false);
        setDeviceType('');
        if (needToLoadDevices) {
            getStoreDetailsFromApi(
                    suid,
                    setStoreDetails,
                    setDeviceDetails,
                    setStoreDetailsLoading,
                    setAXCustomerNumber,
                    setVaioSubscription,
                    setVaioSubscriptionList,
                    setInitialVaioProvider,
                    setNexeoSystemTierID,
                    setJTechTierID,
                    setClassificationId,
                    setNitroSubscriptionId
            );
            addSuccessNotification(SUCCESS_MESSAGE_ADD_DEVICE_T_KEY);
        }
    }, [
        isModalOpen,
        suid
    ]);

    const isAddDeviceButtonDisabled = useMemo(() => {
        if (!availableDevices.length) {
            return true;
        }

        if (deviceDetails.length >= 2) {
            return getActiveDevices(deviceDetails);
        }
        return false;
    }, [deviceDetails, availableDevices]);

    const onConfirmRemoveStore = useCallback(async () => {
        onConfirmPopupClose();
        setStoreDetailsLoading(true);
        try {
            await removeStore(suid);
            const url = getRedirectUrl(uuid);
            navigate(url);
            setTimeout(() => {
                addSuccessNotification(SUCCESS_MESSAGE_REMOVE_STORE_T_KEY);
            }, 1000);
        } catch (error) {
            addErrorNotification(ERROR_MESSAGE_T_KEY);
        } finally {
            setStoreDetailsLoading(false);
        }
    }, [suid, uuid, setStoreDetailsLoading, addSuccessNotification, addErrorNotification, onConfirmPopupClose]);

    const isRemoveStoreButtonVisible = useMemo(() => !deviceDetails.length, [deviceDetails]);

    const onStoreDeleteClick = useCallback(() => {
        setConfirmPopupContent({
            message: REMOVE_STORE_CONFIRM_MESSAGE_T_KEY,
            onConfirm: onConfirmRemoveStore
        });
        setConfirmPopupOpen(true);
    },
    [isConfirmPopupOpen, setConfirmPopupOpen, confirmPopupContent, setConfirmPopupContent]);

    const onAddDevice = useCallback(() => {
        const { id: deviceTypeID } = getDeviceTypeByName(deviceType);

        addPreconfiguredDevice({
            payload: {
                deviceTypeID,
                storeID: storeDetails.Store_ID,
                settingTemplateID: snapshot.SettingTemplateID
            },
            setLoading: setStoreDetailsLoading,
            suid,
            setStoreDetails,
            setDeviceDetails,
            setStoreDetailsLoading,
            setAXCustomerNumber,
            setVaioSubscription,
            setVaioSubscriptionList,
            setInitialVaioProvider,
            setNexeoSystemTierID,
            setJTechTierID,
            setClassificationId,
            setNitroSubscriptionId
        });
        onModalClose();
    }, [snapshot, storeDetails, suid]);

    return {
        snapshot,
        setSnapshot,
        deviceType,
        setDeviceType,
        isStoreDetailsLoading,
        storeDetails,
        devicesRows,
        AXCustomerNumber,
        setAXCustomerNumber,
        isJTechCustomerOnlySubscription,
        isNexeoCustomerOnlySubscription,
        isNitroSubscription,
        isNVDEnabled,
        onCancel,
        onSave,
        isSaveBtnDisabled,
        validationErrors,
        onAddDeviceClick,
        onAddDevice,
        isModalOpen,
        onModalClose,
        deviceDetails,
        availableDevices,
        isAddDeviceButtonDisabled,
        devicesHeaders,
        isConfirmPopupOpen,
        onConfirmPopupClose,
        isRemoveStoreButtonVisible,
        onStoreDeleteClick,
        confirmPopupContent,
        vaioSubscription,
        onVaioSubChange,
        onStoreSystemTierChange,
        onJTechTierChange,
        vaioSubscriptionList,
        nexeoSystemTierID,
        jTechTierID,
        storeSystemTiers,
        isStoreSystemTiersDisabled,
        isStoreSystemTiersLoading,
        initialVaioProvider,
        classificationId,
        setClassificationId,
        hasManageNitroSubscriptionPermission,
        nitroSubscriptionId,
        setNitroSubscriptionId
    };
};
