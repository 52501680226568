/* eslint-disable quote-props */
export const COMMON_ROUTES = {
    welcome: 'welcome',
    error: 'error',
    exception: 'exception',
    forbidden: 'forbidden',
    logout: 'logout',
    settings: 'settings',

    // Report Routes
    overview: '/new-reports/overview',
    multi: '/new-reports/multi',
    single: '/new-reports/single',
    trends: '/new-reports/trends',
    trendsHistory: '/new-reports/trendsHistory',
    rcd: '/new-reports/rcd',
    'outliers-client': '/new-reports/outliers-client',
    'performance-analysis': '/new-reports/performance-analysis',
    'self-service': '/new-reports/self-service',
    scheduledReports: '/new-reports-scheduler',
    scheduledReportCreate: '/new-reports-scheduler/create',
    scheduledReportEdit: '/new-reports-scheduler/edit'
};

export const PUBLIC_ROUTES = {
    account: 'profile/account',
    companyInfo: 'profile/company',
    brandSharing: 'profile/brand-sharing',
    newAccount: 'newAccount',

    storeSettings: '/settings/stores/edit',

    // authentication
    logout: '/logout',

    // manage-report-groups
    manageReportGroups: 'stores/hierarchy',

    // leaderboards
    lbGroups: 'leaderBoards/LBGroups',
    lbGroup: 'leaderBoards/LBGroups/lbGroup',

    // leaderboard contest
    contestsList: '/leaderBoards/contests',
    newContest: '/leaderBoards/contests/new',
    contestsResult: (uid) => `/leaderBoards/contests/${uid}/results`,
    contestDetails: (uid) => `/leaderBoards/contests/${uid}`,
    contestCertificate: (uid) => `/leaderBoards/contests/${uid}/certificate`
};

export const ADMIN_ROUTES = {
    storeSettings: '/admin/settings/stores/edit',
    customerNotification: '/admin/customerNotifications',

    // authentication
    logout: '/admin/logout',

    // account
    createAccount: '/admin/account',

    // service provider settings (ex. VaioProvider)
    serviceProviders: '/admin/vaioProviders',
    editVaioProvider: (uid) => `/admin/editVaioProvider?puid=${uid}`,

    // token management
    tokenManagement: '/admin/tokenManagement',
    createAPIToken: '/admin/createAPIToken',
    speedOfServiceSubscription: '/admin/reports/speed-of-service',
    speedOfServiceDetection: '/admin/reports/speed-of-service-detection'
};

export const PARTNER_ROUTES = {
    [COMMON_ROUTES.welcome]: COMMON_ROUTES.welcome,
    stores: 'partner/stores',
    settingsFull: 'partner/settings',
    tokenManagement: 'partner/tokenManagement',
    editAPIToken: (uid) => `/partner/tokenManagement/edit?uid=${uid}`,

    // authentication
    logout: '/partner/logout',

    // account management
    account: 'account',
    accountInfoFull: 'partner/account'
};
